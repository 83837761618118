import React from 'react'
import { graphql } from 'gatsby'
import "../assets/index.css"
import Layout from "../layouts/layout"
import Hero from "../components/hero-career-post";
import styled from "styled-components"

export const query = graphql`
  query CategoryTemplateQuery($id: String!) {
    category: sanityCareer(id: {eq: $id}) {
        name
        description
        responsibilities
        experience
    }
  }
`

const CategoryPostTemplate = props => {
    const { data = {} } = props
    const { name, description, responsibilities, experience } = data.category || {}

    const WrapperDiv = styled.div`
        max-width: 1080px;
        margin: 0 auto 40px;
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        margin-bottom: 160px;
    `;

    const ListingContainer = styled.div`
        display: flex;
        box-sizing: border-box;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 40px;
    `

    const ListingLeft = styled.div`
        width: 60%;
        padding-right: 22px;

        @media only screen and (max-width: 1024px) {
            width: 100%;
            padding: 0 20px;
        }
    `

    const ListingRight = styled.div`
        width: 40%;
        padding-left: 22px;
        padding-top: 46px;

        @media only screen and (max-width: 1024px) {
            width: 100%;
            padding: 0 20px;
            margin-top: 0px;
        }
    `

    const ListingLink = styled.a`
        font-size: 14px;
        color: #23C0F5;
        font-weight: 500;
        margin-bottom: 25px;
        display: inline-block;
        cursor: pointer;

        svg {
            transform: rotate(-90deg);
            position: relative;
            top: -2px;
            left: 2px;
        }
    `
    const ListingH2 = styled.h2 `
        font-size: 30px;
        line-height: 38px;
        font-weight: 600;
        color: #6A7C92;
        margin-bottom: 10px;

    `
    const ListingH3 = styled.h3`
        font-size: 18px;
        line-height: 26px;
        font-weight: 600;
        color: #6a7c92;
        margin-top: 40px;
        margin-bottom: 10px;

        @media only screen and (max-width: 1024px) {
            font-size: 18px;
            margin-top: 20px;
        }

    `;

    const ListingP = styled.p`
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #6A7C92;
    `

    const ListingDesP = styled.p`
        font-size: 16px;
        line-height: 24px;
        color: #6A7C92;

        @media only screen and (max-width: 1024px) {
            display: none;
        }
    `

    const ListingDesPMobile = styled.p`
        font-size: 16px;
        line-height: 24px;
        color: #6A7C92;
        display: none;

        @media only screen and (max-width: 1024px) {
            display: block;
        }
    `

    const ListingUl = styled.ul`
        font-size: 16px;
        line-height: 22px;
        color: #6A7C92;
        list-style: none;

        li {
            padding-left: 0px;
            position: relative;

            &:before {
                content: "•";
                position: absolute;
                left: -20px;
                padding-right: 8px;
                font-size: 30px;
                margin-top: -2px;
            }
        }
    `

    const ApplyButton = styled.a`
        background: #23C0F5;
        border: 1px solid #23C0F5;
        width: 260px;
        text-align: center;
        text-transform: uppercase;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        color: #fff;
        padding: 15px 22px;
        margin: auto;
        display: inline-block;
        margin-top: 20px;
        
        :focus, :hover {
            outline: none;
            background: #71D6F8;
            border-color: #71D6F8;
        }

        :active {
            background: #037EA6;
            border-color: #037EA6;
        }

        @media only screen and (max-width: 1024px) {
            margin: 20px 0;
            width: 100%;
        }    
    `

    const chevronSVG = () => (
        <svg width="7px" height="5px" viewBox="0 0 7 5" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Desktop--Career" transform="translate(-370.000000, -4020.000000)" stroke="#23C0F5">
                    <g id="JOB-2" transform="translate(179.000000, 4009.000000)">
                        <polyline id="Chevron-Copy" transform="translate(195.000000, 13.500000) rotate(-180.000000) translate(-195.000000, -13.500000) " points="192 12 195.119117 15 198 12"></polyline>
                    </g>
                </g>
            </g>
        </svg>
    )
    return (
        <Layout title={name + " | Ladd Partners"}>
            <Hero></Hero>
            <WrapperDiv>
                <ListingContainer>
                    <ListingLeft>
                        <ListingLink href="/company/careers?listings">{chevronSVG()} Back to Current Openings </ListingLink>
                        <ListingH2>{name}</ListingH2>
                        <ListingP>Location: Remote </ListingP>
                        {description.map(des => <ListingDesP>{ des }</ListingDesP>)}
                        <ListingH3>Key responsibilities will include:</ListingH3>
                        <ListingUl>
                            {responsibilities.map(res => <li>{ res }</li>)}
                        </ListingUl>

                        {experience.length > 0 &&
                            <>
                            <ListingH3>Experience required:</ListingH3>
                            <ListingUl>
                                {experience.map(exp => <li>{ exp }</li>)}
                            </ListingUl>
                            </>
                        }
                    </ListingLeft>
                    <ListingRight>
                        <ListingDesP>To apply for this job email your details to: <ListingLink href={`mailto:jobs@laddpartners.com?subject=${name}`}>jobs@laddpartners.com</ListingLink></ListingDesP>
                        <ListingH3>Benefits:</ListingH3>
                        <ListingUl>
                            <li>Competitive compensation</li>
                            <li>Full benefits: <br/> 
                                Medical <br/>
                                Dental/Vision <br/>
                                Life insurance <br/>
                                Disability <br/>
                                401K <br/>
                                Flex spending plan (FSP) <br/>
                            </li>
                            <li>Training allowance of $2,000 / year</li>
                            <li>Work Remotely with flexible hours </li>
                            <li>"No meeting Fridays" once a month</li>
                        </ListingUl>

                        <ApplyButton href={`mailto:jobs@laddpartners.com?subject=${name}`}> Apply Now </ApplyButton>
                        <ListingDesPMobile>To apply for this job email your details to:  <ListingLink href={`mailto:jobs@laddpartners.com?subject=${name}`}>jobs@laddpartners.com</ListingLink></ListingDesPMobile>

                    </ListingRight>
                </ListingContainer>
            </WrapperDiv>
        </Layout>
    )
}

export default CategoryPostTemplate